import Head from 'next/head';
import type { FC } from 'react';

import { __STAGING__, APPLICANT_BASE_URL } from '../../utils/constants';

export interface PageHeadProps {
  title: string;
  description: string;
  image?: string;
  canonicalLink: string;
  noIndex?: boolean;
  prev?: string;
  next?: string;

  type?: string;
  publishedAt?: string;
  twitterCardType?: string;
  twitterHandle?: string;
}

const normalizeRelativeLink = (url: string) =>
  /https?:\/\//.test(url) ? url : `${APPLICANT_BASE_URL}${url}`;

const PageHead: FC<PageHeadProps> = ({
  title: titleProp,
  image = '/images/leland-og.png',
  canonicalLink,
  noIndex,
  prev,
  next,
  type = 'website',
  description,
  publishedAt,
  twitterCardType = 'summary_large_image',
  twitterHandle = '@joinLeland',
}) => {
  const title = `${titleProp} | Leland`;

  return (
    <Head>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <title key="title">{title}</title>
      <link
        key="canonical-link"
        rel="canonical"
        href={normalizeRelativeLink(canonicalLink)}
      />
      {noIndex || __STAGING__ ? (
        <meta key="robots" name="robots" content="noindex" />
      ) : null}
      {prev ? (
        <link key="prev" rel="prev" href={normalizeRelativeLink(prev)} />
      ) : null}
      {next ? (
        <link key="next" rel="next" href={normalizeRelativeLink(next)} />
      ) : null}

      {/* OpenGraphTags */}
      <meta property="og:type" content={type} key="ogtype" />
      <meta property="og:title" content={title} key="ogtitle" />
      {description ? (
        <meta property="og:description" content={description} key="ogdesc" />
      ) : null}
      {description ? (
        <meta name="description" content={description} key="desc" />
      ) : null}
      <meta property="og:image" content={image} key="ogimage" />

      {publishedAt ? (
        <meta
          property="article:published_time"
          content={publishedAt}
          key="published_time"
        />
      ) : null}

      <meta name="twitter:card" content={twitterCardType} key="twcard" />
      <meta name="twitter:creator" content={twitterHandle} key="twhandle" />
      <meta name="twitter:title" content={title} key="twtitle" />
      <meta name="twitter:description" content={description} key="twdesc" />
      <meta name="twitter:image" content={image} key="twimage" />
    </Head>
  );
};

export default PageHead;
