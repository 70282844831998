import {
  type ComponentPropsWithoutRef,
  type FC,
  type PropsWithChildren,
  useMemo,
} from 'react';

import { BASE_LELAND_GO_URL } from '../../utils/constants';
import {
  getNavCategoriesFromFsCache,
  navCategoriesDataFetcher,
} from '../../utils/fs-cache/nav-categories-cache';
import AnnouncementBanner from '../AnnouncementBanner';
import { LulNavbar } from '../LulNavbar';
import MainFooter from '../MainFooter';

import PageHead, { type PageHeadProps } from './PageHead';
import {
  type LayoutNavCategoryListingFragment,
  useLayoutHeaderDataQuery,
} from './__generated-gql-types__/Layout.generated';

type Props = PropsWithChildren<
  PageHeadProps & ComponentPropsWithoutRef<typeof LulNavbar>
> & {
  hideAnnouncementBanner?: boolean;
};

export type LayoutDataProps = ComponentPropsWithoutRef<
  typeof LulNavbar
>['pageContentData'];

export const getStaticLayoutQuery = async (): Promise<LayoutDataProps> => {
  let navCategoryListings: LayoutNavCategoryListingFragment[];
  try {
    navCategoryListings = await getNavCategoriesFromFsCache();
  } catch {
    navCategoryListings = (await navCategoriesDataFetcher()).navCategories;
  }
  return { navCategoryListings };
};

// show until 12/2 midnight MT (cyber monday)
const BLACK_FRIDAY_END_DATE = new Date('2024-12-03T07:00:00.000Z').getTime();

export const useLayoutDataQuery = (): LayoutDataProps => {
  const { data } = useLayoutHeaderDataQuery();

  return useMemo(
    () => ({
      navCategoryListings: data?.navCategories ?? [],
    }),
    [data],
  );
};

const Layout: FC<Props> = ({
  children,
  pageContentData,
  hideAnnouncementBanner,
  ...pageHeadProps
}) => {
  const clientPageContentData = useLayoutDataQuery();

  const pageContentDataToUse = clientPageContentData?.navCategoryListings.length
    ? clientPageContentData
    : pageContentData;
  return (
    <>
      <PageHead {...pageHeadProps} />

      {/* BODY */}
      <div className="flex flex-col-reverse">
        <MainFooter />
        <main className="min-h-screen">{children}</main>
        <LulNavbar pageContentData={pageContentDataToUse} />
        {hideAnnouncementBanner ? null : (
          <AnnouncementBanner
            href={`${BASE_LELAND_GO_URL}/leland-gift-card`}
            text={
              <>Black Friday Sale — Buy a Leland gift card for 10%&nbsp;off!</>
            }
            until={BLACK_FRIDAY_END_DATE}
            dismissable
          />
        )}
      </div>
    </>
  );
};

export default Layout;
